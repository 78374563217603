<template>
<div id="page" class="equipment-for-index">
  <div class="qfi-search-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item label="订单状态" prop="orderstatus">
        <el-select v-model="form.orderstatus" placeholder="请选择" size="small">
          <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item prop="orderno">
        <el-input v-model="form.orderno" placeholder="申领单号" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
        <el-button type="primary" @click="handleExport()">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_realname" label="省级合伙人" align="center" />
      <el-table-column prop="dt_order" label="申领时间" align="center" />
      <el-table-column prop="txt_order_number" label="申领单号" align="center" />
      <el-table-column prop="dec_order_amount" label="设备总金额" align="center">
        <template #default="scope">￥{{scope.row.dec_order_amount}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" :class-name="isDisabled?'isDisabled':'table-option'">
        <template #default="scope">
          <template v-if="status=='0'">
            <div class="table-icon" @click="changeStatus(scope.row, '2')">
              <el-icon>
                <Check />
              </el-icon> 确认收款
            </div>
          </template>
          <template v-else>
            <div class="table-icon" @click="changeStatus(scope.row, '0')">
              <el-icon>
                <Close />
              </el-icon> 取消确认
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, computed } from 'vue';
import { getEqOrderList, changeEqOrderStatus } from "api/apis.js";
import { ElMessage, FormInstance, ElMessageBox } from 'element-plus';
import { Check, Close } from "@element-plus/icons-vue";
import { utilExport } from "assets/js/export";
import {useStore} from 'vuex';
export default {
  components: {
    Check,
    Close
  },
  setup() {
    const store = useStore();
    const state = reactive({
      formRef: ref(FormInstance),
      form: {
        orderstatus: "0", // "0"-未确认  "1"-已确认
        orderno: null
      },
      status: "0",
      tableData: [],
      tabelLoading: false,
      statusOption: [
        { id: 2, value: "0", label: "未确认" },
        { id: 3, value: "2", label: "已确认" }
      ],
      isDisabled: computed(() => store.getters.isDisabled )
    });

    onMounted(() => {
      getList();
    })

    const getList = () => {
      state.tabelLoading = true;
      let param = state.form;
      // 判断是否需要传递该参数
      if ((param.orderno || "").trim() == "") {
        delete param.orderno;
      }
      getEqOrderList(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.tableData = response.data;
            state.status = state.form.orderstatus;
          } else {
            state.tableData = [];
          }
        } else {
          ElMessage.error(`${response.msg}`);
        }
      }).catch(e => {
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    // 待定：导出函数
    const handleExport = () => {
      utilExport(state.tableData, state.form.orderstatus)
    }

    // 修改订单状态
    const changeStatus = (item, status) => {
      ElMessageBox.confirm(
        `确定要${status == '0' ? '【取消】' : '【确认】'}订单${item.txt_order_number}吗？`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '是的',
          cancelButtonText: '再想想',
        }
      ).then(() => {
        state.tabelLoading = true;
        changeEqOrderStatus({
          orderid: item.t_level1_order_id,
          orderstatus: status
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success(status == '0' ? '取消成功' : '确认成功');
            getList();
          } else {
            ElMessage.error(`${response.msg}`);
          }
        }).catch(e => {
          ElMessage.error(`${e}`);
        }).finally(() => {
          state.tabelLoading = false;
        })
      }).catch(() => {
        console.log("取消操作")
      })
    }

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      getList();
    };

    return {
      ...toRefs(state),
      reset,
      getList,
      changeStatus,
      handleExport
    }
  }
}
</script>

<style lang="scss">
.equipment-for-index {
  .qfi-search-part {
    height: 100%;

    .el-table {
      height: calc(100% - 55px);
    }
  }
}

.el-message-box__btns {
  .el-button {
    color: #606266 !important;
  }

  .el-button--primary {
    color: #ffffff !important;
    background-color: #409eff !important;
  }
}
</style>
